import React from "react"
import styled from "styled-components"
import map from "lodash/map"

import { VerticalSection } from "components/common"

import { device } from "utils"

import TeamVP from "./TeamVP"

const TeamGrid = ({ title, subtitle, components }) => {
  const renderTeam = () => (
    <TeamList>
      {map(components, (teamMember) => {
        return <TeamVP key={teamMember.id} {...teamMember} />
      })}
    </TeamList>
  )

  return (
    <StyledTeamGrid>
      <VerticalSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        grid={renderTeam}
      />
    </StyledTeamGrid>
  )
}

const StyledTeamGrid = styled.div`
  --tg-max-width: 660px;
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);

  .gridWrap {
    /* margin-top: var(--sp-section); */
  }
`

const TeamList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--sp-16);

  @media ${device.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(auto, 175px));
  }

  @media ${device.laptop} {
    grid-gap: var(--sp-48);
    grid-template-columns: repeat(5, minmax(auto, 175px));
  }

  justify-content: center;
`

export default TeamGrid
