import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta, useContentfulEdges } from "utils"

import { TeamGrid } from "../sections/team"

import AuthorEmpty from "src/sections/author/AuthorEmpty"

import { CtaGetDemo } from "components/cta"

const TeamPage = ({ data: { author, authors } }) => {
  const { author_grid, cta_get_demo } = useContentfulSection(author.sections)

  const authorsList = useContentfulEdges(authors)

  author_grid.components = authorsList

  const meta = useMeta(author)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        {!authorsList.length ? <AuthorEmpty /> : <TeamGrid {...author_grid} />}

        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    author: contentfulPage(pageId: { eq: "author" }) {
      ...Page
    }
    authors: allContentfulTeamMember(filter: { isAuthorOnly: { eq: true } }) {
      edges {
        node {
          ...TeamMember
        }
      }
    }
  }
`

export default TeamPage
