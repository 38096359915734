import React from "react"
import styled from "styled-components"

import { Button, Link } from "components/common"
import { device } from "utils"

import Icon from "images/common/iconBlog.inline.svg"

const AuthorEmpty = () => {
  return (
    <StyledAuthorEmpty>
      <h1>Authors</h1>

      <div className="empty">
        <Icon />

        <h5>
          We don’t have any guest authors right now, but we will soon.
          Interested in writing for us?
          <a
            href="mailto:robin@backboneiq.com"
            target="_blank"
            rel="noreferrer"
          >
            Shoot us a line.
          </a>
        </h5>
        <Link to="/blog">
          <Button>Go to the blog</Button>
        </Link>
      </div>
    </StyledAuthorEmpty>
  )
}

const StyledAuthorEmpty = styled.div`
  padding-top: var(--sp-mobile-nav);
  padding-bottom: var(--sp-section);
  text-align: center;
  @media ${device.laptop} {
    padding-top: var(--sp-nav);
  }

  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: var(--sp-80);

    @media ${device.laptop} {
      margin-top: var(--sp-section);
    }

    h5 {
      margin: var(--sp-24) auto var(--sp-48);
      max-width: 415px;

      a {
        margin-left: 5px;
        display: inline-block;
        font-size: 1.5rem;
        line-height: 32px;
        font-weight: var(--title-font-weight);
      }
    }
  }
`

export default AuthorEmpty
