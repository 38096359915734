import React from "react"
import styled from "styled-components"

import { Media, Link } from "components/common"

import { useRandomNoRepeats } from "utils"

const brandColors = [
  "cannabis",
  "forest",
  "moss",
  "seafoam",
  "shoreline",
  "blueberry",
  "water",
]

const TeamVP = ({ photo, fullName, role, slug, isAuthorOnly }) => {
  const color = useRandomNoRepeats(brandColors)

  const to = isAuthorOnly ? `/author/${slug}` : `/team/${slug}`

  return (
    <StyledTeamVP color={color}>
      <Link to={to}>
        <Media className="authorPhoto" media={{ desktop: photo }} />
        <div className="details">
          <p className="small fullName">{fullName}</p>
          <p className="small role">{role}</p>
        </div>
      </Link>
    </StyledTeamVP>
  )
}

const StyledTeamVP = styled.div`
  a {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: var(--sp-8);
  }

  &:hover {
    .authorPhoto {
      transform: scale(1.05);
    }
  }

  .authorPhoto {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    position: relative;
    border: 2px solid transparent;
    box-shadow: ${({ color }) => `
    0 0 0 2px var(--${color}),
    0px 20px 48px -16px var(--${color})
    `};

    transition: 0.2s transform ease-in-out;
    text-align: center;
    margin: 0 auto;

    img {
      border-radius: 50%;
    }
  }

  .details {
    text-align: center;
    .fullName {
      color: var(--spruce);
    }

    .fullName,
    .role {
      font-weight: 500;
    }

    .role {
      font-size: 0.875rem;
      line-height: 20px;
    }
  }
`

export default TeamVP
